import { Box, Heading, List, ListIcon, ListItem, Text } from "@chakra-ui/react"
import { FunctionComponent } from "react"
import { FaCheck } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import Button from "../components/common/Button"
import { usePlayer } from "../hooks/query"

const RulesPage: FunctionComponent = () => {
    const navigate = useNavigate()

    return (
        <Box p="4">
            <Heading as="h1" mb="4">
                Guidelines
            </Heading>

            <List styleType="disc" pl="8">
                <ListItem>Be nice and tolerant: no racism, sexism, homophobia, insults, harassment, etc...</ListItem>
                <ListItem>No link to pornographic or violent content</ListItem>
                <ListItem>Only one account per player</ListItem>
                <ListItem>
                    No cheating. This includes :
                    <List styleType="disc" pl="8">
                        <ListItem>Spying on other players (projects, other faction chats, etc...)</ListItem>
                        <ListItem>Bots / automated scripts</ListItem>
                        <ListItem>Using exploits to get an advantage</ListItem>
                    </List>
                </ListItem>
            </List>

            <Text my="8">
                If you don&apos;t follow this simple rules, you could be forbidden from talking in the chat or simply be
                banned from the game.
            </Text>
        </Box>
    )
}

export default RulesPage
