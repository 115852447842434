import { Box, Button, Checkbox, FormControl, FormHelperText, HStack, Input, Text, useTheme } from "@chakra-ui/react"
import { FunctionComponent, useState } from "react"
import { usePlayer } from "../../hooks/query"
import { observer } from "mobx-react-lite"
import { useStore } from "../../store"

const MapUI: FunctionComponent = observer(() => {
    const { settingsStore } = useStore()
    const theme = useTheme()

    const player = usePlayer()
    const bgColor = theme.app.factions[player?.faction || ""] || "orange.300"

    return (
        <Box id="map-ui" bg={bgColor}>
            <FormControl px="2">
                <HStack alignItems={"center"}>
                    <Checkbox
                        isChecked={settingsStore.showMapSoldiers}
                        onChange={() => settingsStore.setShowMapSoldiers(!settingsStore.showMapSoldiers)}
                    >
                        Show soldiers
                    </Checkbox>
                </HStack>
            </FormControl>
        </Box>
    )
})

export default MapUI
