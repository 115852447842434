import { HStack, Td, Text, Tr } from "@chakra-ui/react"
import { FunctionComponent } from "react"
import { Role } from "../../types"
import Link from "../common/Link"
import { default as RoleComponent } from "../common/Role"

interface PlayerProps {
    id: number
    name: string
    role: Role
    hq_level: number
    spec: string
}
const Player: FunctionComponent<PlayerProps> = ({ id, name, hq_level, role, spec }) => {
    return (
        <Tr>
            <Td>
                <Link to={`/player/${name}-${id}`} color="orange.100">
                    {name}
                </Link>
            </Td>
            <Td>{hq_level}</Td>
            <Td>
                <RoleComponent name={role} />
            </Td>
            <Td>{spec}</Td>
        </Tr>
    )
}

export default Player
