import { TextProps, chakra, Text, Box } from "@chakra-ui/react"
import React, { FunctionComponent, useRef, useEffect } from "react"
import { formatNumber } from "../../utils/numbers"
import { AnimatedBox, AnimatedSpan } from "./Motion";
import { createPopper } from '@popperjs/core';
import { usePrevious } from "../../hooks/utils";
import { motion } from "framer-motion";

const MotionSpan = motion.span

interface NumberProps extends TextProps {
    value: number
    percentage?: boolean
    showPlus?: boolean
    animated?: boolean
    numOfFractionDigits?: number
    floor?: boolean /** if true, the number is floored to the nearest integer */
}
const Number: FunctionComponent<NumberProps> = ({
    value,
    percentage = false,
    showPlus = false,
    animated = false,
    numOfFractionDigits = 1,
    floor = false,
    ...props
}) => {

    const prevValue = usePrevious(value)
    const diff = prevValue !== null ? value - (prevValue as number) : null
    const diffFormated = diff !== null ? formatNumber(diff, { numOfFractionDigits }) : null

    const formatted = formatNumber(floor ? Math.floor(value) : value, { numOfFractionDigits })

    if (value === undefined) return <Text {...props} as="span">N/A</Text>

    //   if (forceNegative)
    //     valueStyle = styles.negative
    //   else if (baseValue !== null)
    //     if (value > baseValue) valueStyle = styles.positive
    //     else if (value < baseValue) valueStyle = styles.negative
    return (
        <Text {...props} pos="relative" display="inline" as={"span"}>
            {/* make text appears floating brievely */}

            {animated && diff !== null && diff > 0 && diffFormated && <MotionSpan style={{ position: "absolute" }} key={value}
                initial={{
                    x: 0,
                    y: 0,
                    scale: 0,
                    rotate: 0,
                    opacity: 1
                }}
                transition={{ duration: 1, times: [0, 0.5, 0.8, 1] }}
                animate={{
                    x: 0,
                    y: [0, -20, -20, -10],
                    scale: [0, 1, 1, 0.8],
                    rotate: 0,
                    opacity: [0, 1, 1, 0],
                }}><Text as="span" textShadow="black 2px 2px 4px;" margin="auto" color={diff > 0 ? "green.400" : "red.400"}>{diff > 0 && "+"}{diffFormated.formatted}</Text></MotionSpan>}

            {value >= 0 && showPlus && "+"}
            {formatted.formatted}
            {percentage && "%"}
        </Text>
    )
}

export default chakra(Number)
