import {
    Box,
    Center,
    Divider,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    HStack,
    Heading,
    IconButton,
    Stack,
    Text,
    useDisclosure,
    useTheme,
} from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { FunctionComponent } from "react"
import { useGame, useHQInfo, usePlayer } from "../../hooks/query"
import { useIsMobile } from "../../hooks/utils"
import { useStore } from "../../store"
import GatherProgress from "../GatherProgress"
import CurrentResources from "../resources/CurrentResources"
import ConnectedPlayers from "./header/Connected"
import ProfileButton from "./header/ProfileButton"
import Victory from "./header/Victory"

import Link from "../common/Link"
import SleepMode from "./header/SleepMode"
import { HamburgerIcon } from "@chakra-ui/icons"

const Header: FunctionComponent = observer(() => {
    const { userStore } = useStore()
    const isMobile = useIsMobile()
    const theme = useTheme()

    // mobile drawer
    const { isOpen, onOpen, onClose } = useDisclosure()
    const player = usePlayer()

    const { data } = useHQInfo()
    const { data: game } = useGame()
    const sleepMode = data?.hq.sleepMode
    const bgColor = sleepMode ? "black" : theme.app.factions[player?.faction || ""] || "orange.300"

    return (
        <Box
            id="navigation"
            as="nav"
            role="navigation"
            // bg="orange.300"
            w="full"
            color="orange.100"
            display="flex"
            flexDir="column"
            justifyContent={"space-between"}
            position="sticky"
            top="0"
            p="2"
            zIndex="2"
            bg={bgColor}
            transition="background-color 0.5s"
        >
            <Flex justify="space-between" onClick={() => isMobile && onOpen()}>
                {!isMobile && (
                    <>
                        <Stack gap="0">
                            <Heading>
                                <Link to={`/`} variant="transparent">
                                    Factions
                                </Link>
                            </Heading>
                            <Link href="https://discord.gg/DsKSmetnqV" target="_blank" color="orange.100">
                                Join the discord !
                            </Link>
                        </Stack>
                        <Stack>
                            <Text as="span">{data?.hq.faction}</Text>
                            <ConnectedPlayers />
                        </Stack>
                    </>
                )}
                {isMobile && <IconButton size="sm" icon={<HamburgerIcon />} onClick={onOpen} aria-label="Menu" variant="ghost" />}
                <CurrentResources collapsable={false} simple={isMobile} />
                {!isMobile && (
                    <Box mr="2">
                        <SleepMode />
                    </Box>
                )}

                {!isMobile && <ProfileButton />}
            </Flex>

            <Box w="full">
                <GatherProgress />
            </Box>

            {game?.winning && <Victory />}

            <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
                <DrawerOverlay />
                <DrawerContent bgColor={bgColor}>
                    <DrawerCloseButton />
                    <DrawerHeader>
                        <Link to="/" variant="transparent">
                            Factions
                        </Link>
                    </DrawerHeader>

                    <DrawerBody>
                        <Stack>
                            <Link href="https://discord.gg/DsKSmetnqV" target="_blank" color="orange.100">
                                Join the discord !
                            </Link>
                            <HStack>
                                <Text>Connected players : </Text>
                                <ConnectedPlayers />
                            </HStack>
                            <Divider />
                            <SleepMode />
                            <Divider />
                            <Box flexGrow={1}>
                                <CurrentResources collapsable={true} />
                            </Box>
                        </Stack>
                    </DrawerBody>

                    <DrawerFooter>
                        <Center>
                            <HStack gap="2">
                                <Text>{player?.username}</Text>
                                <ProfileButton />
                            </HStack>
                        </Center>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </Box>
    )
})

export default Header
