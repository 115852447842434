import { Config } from "../../../api/game";
import { FactionTileInfo } from "../../../api/world";
import { WORLD_SIZE } from "../../../config";
import { queryClient } from "../../../query_client";
import { Factions } from "../../../types";
import { GameScene } from "../scene/world";

interface WallsConstructor {
    scene: GameScene
}
type WallInfo = {
    hasWall: boolean
    hasImprovement: boolean
    // isNextToFrontier: boolean
    faction: Factions
    top: boolean
    right: boolean
    bottom: boolean
    left: boolean
    wallObjects: Phaser.GameObjects.Sprite[]
    improveObject: Phaser.GameObjects.Sprite | null
}

/** hande walls info */
export class Walls {

    scene: GameScene
    wallsLayer: Phaser.GameObjects.Layer
    walls: WallInfo[][]
    world: FactionTileInfo[][]
    showWallThreshold = 1
    showImprovementThreshold = 1

    constructor({ scene }: WallsConstructor) {
        this.scene = scene

        this.wallsLayer = scene.add.layer().setDepth(4)

    }

    // getFrontiers(x: number, y: number) {
    //     const tile = this.world[x][y]
    //     const frontiers = []
    //     if (x < WORLD_SIZE - 1 && this.world[x + 1][y].f !== tile.f) {
    //         frontiers.push('right')
    //     }
    //     if (y < WORLD_SIZE - 1 && this.world[x][y + 1].f !== tile.f) {
    //         frontiers.push('bottom')
    //     }
    //     if (x > 0 && this.world[x - 1][y].f !== tile.f) {
    //         frontiers.push('left')
    //     }
    //     if (y > 0 && this.world[x][y - 1].f !== tile.f) {
    //         frontiers.push('top')
    //     }
    //     return frontiers
    // }

    getNextWalls(x: number, y: number, faction: Factions) {
        const walls = []
        if (x < WORLD_SIZE - 1 && this.world[x + 1][y].f === faction && this.world[x + 1][y].w && this.world[x + 1][y].w![0] >= this.showWallThreshold) {
            walls.push('right')
        }
        if (y < WORLD_SIZE - 1 && this.world[x][y + 1].f === faction && this.world[x][y + 1].w && this.world[x][y + 1].w![0] >= this.showWallThreshold) {
            walls.push('bottom')
        }
        if (x > 0 && this.world[x - 1][y].f === faction && this.world[x - 1][y].w && this.world[x - 1][y].w![0] >= this.showWallThreshold) {
            walls.push('left')
        }
        if (y > 0 && this.world[x][y - 1].f === faction && this.world[x][y - 1].w && this.world[x][y - 1].w![0] >= this.showWallThreshold) {
            walls.push('top')
        }
        return walls
    }

    getFactionIndex(faction: Factions) {
        if (faction === Factions.BLUE) return 0
        if (faction === Factions.GREEN) return 1
        if (faction === Factions.RED) return 2
        if (faction === Factions.YELLOW) return 3
        return -1
    }

    buildWallsInfo() {
        this.wallsLayer.removeAll(true)
        const config = queryClient.getQueryData(["config", this.scene.gameId]) as Config
        this.showWallThreshold = config.misc.fortificationTileLevels[0]
        this.showImprovementThreshold = config.misc.improvementTileLevels[0]
        this.world = this.scene.invasion.world
        this.walls = []

        // cleanup
        for (let x = 0; x < this.walls.length; x++) {
            for (let y = 0; y < this.walls[x].length; y++) {
                this.walls[x][y].wallObjects.forEach(o => o.destroy())
                if (this.walls[x][y].improveObject) this.walls[x][y].improveObject!.destroy()
            }
        }

        for (let x = 0; x < WORLD_SIZE; x++) {
            this.walls[x] = []
            for (let y = 0; y < WORLD_SIZE; y++) {
                const tile = this.world[x][y]
                const hasWall = tile.w ? tile.w[0] >= this.showWallThreshold : false
                const faction = tile.f
                const factionIndex = this.getFactionIndex(faction)
                const hasImprovement = (factionIndex as number) >= 0 && tile.w && tile.w[1] ? tile.w[1][factionIndex as number] >= this.showImprovementThreshold : false
                const improveObject = hasImprovement ? this.scene.add.sprite(x * 32, y * 32, 'misc32', 4).setOrigin(0).setScale(0.3).setDepth(10) : null
                if (improveObject)
                    this.wallsLayer.add(improveObject)
                this.walls[x].push({
                    hasWall,
                    hasImprovement,
                    faction: tile.f,
                    top: hasWall,
                    right: hasWall,
                    bottom: hasWall,
                    left: hasWall,
                    wallObjects: [],
                    improveObject: improveObject
                })

            }
        }
        for (let x = 0; x < WORLD_SIZE; x++) {
            for (let y = 0; y < WORLD_SIZE; y++) {
                if (this.walls[x][y].hasWall) {

                    const directions = this.getNextWalls(x, y, this.world[x][y].f)
                    if (directions.includes('top')) {
                        this.walls[x][y].top = false
                    }
                    if (directions.includes('right')) {
                        this.walls[x][y].right = false
                    }
                    if (directions.includes('bottom')) {
                        this.walls[x][y].bottom = false
                    }
                    if (directions.includes('left')) {
                        this.walls[x][y].left = false
                    }

                    this.walls[x][y].wallObjects = []


                    if (this.walls[x][y].top) {
                        const top = this.scene.add.sprite(x * 32, y * 32, 'misc32', 0).setOrigin(0).setDepth(4)
                        this.wallsLayer.add(top)
                        this.walls[x][y].wallObjects.push(top)
                    }
                    if (this.walls[x][y].right) {
                        const right = this.scene.add.sprite(x * 32, y * 32, 'misc32', 1).setOrigin(0).setDepth(4)
                        this.wallsLayer.add(right)
                        this.walls[x][y].wallObjects.push(right)
                    }
                    if (this.walls[x][y].left) {
                        const left = this.scene.add.sprite(x * 32, y * 32, 'misc32', 3).setOrigin(0).setDepth(4)
                        this.wallsLayer.add(left)
                        this.walls[x][y].wallObjects.push(left)
                    }
                    if (this.walls[x][y].bottom) {
                        const bottom = this.scene.add.sprite(x * 32, y * 32, 'misc32', 2).setOrigin(0).setDepth(4)
                        this.wallsLayer.add(bottom)
                        this.walls[x][y].wallObjects.push(bottom)
                    }
                }
            }
        }
    }

    updateWall(x: number, y: number, recomputeNeighbors = false) {
        const tile = this.world[x][y]
        const hasWall = tile.w ? tile.w[0] >= this.showWallThreshold : false

        const faction = tile.f
        const factionIndex = this.getFactionIndex(faction)
        const hasImprovement = (factionIndex as number) >= 0 && tile.w && tile.w[1] ? tile.w[1][factionIndex as number] >= this.showImprovementThreshold : false
        // if (hasWall !== this.walls[x][y].hasWall) {
        //     this.buildWallsInfo()
        // }
        // destroy old walls
        this.walls[x][y].wallObjects.forEach(o => o.destroy())
        if (this.walls[x][y].improveObject) this.walls[x][y].improveObject!.destroy()
        const directions = this.getNextWalls(x, y, this.world[x][y].f)
        // console.log(directions, x, y, hasWall)
        // new wall object
        const improveObject = hasImprovement ? this.scene.add.sprite(x * 32, y * 32, 'misc32', 4).setOrigin(0).setScale(0.3).setDepth(10) : null
        if (improveObject)
            this.wallsLayer.add(improveObject)
        this.walls[x][y] = {
            hasWall,
            hasImprovement,
            faction: tile.f,
            top: hasWall && !directions.includes('top'),
            right: hasWall && !directions.includes('right'),
            bottom: hasWall && !directions.includes('bottom'),
            left: hasWall && !directions.includes('left'),
            wallObjects: [],
            improveObject: improveObject
        }
        if (this.walls[x][y].top) {
            const wall = this.scene.add.sprite(x * 32, y * 32, 'misc32', 0).setOrigin(0).setDepth(4)
            this.wallsLayer.add(wall)
            this.walls[x][y].wallObjects.push(wall)
        }
        if (this.walls[x][y].right) {
            const wall = this.scene.add.sprite(x * 32, y * 32, 'misc32', 1).setOrigin(0).setDepth(4)
            this.wallsLayer.add(wall)
            this.walls[x][y].wallObjects.push(wall)
        }
        if (this.walls[x][y].left) {
            const wall = this.scene.add.sprite(x * 32, y * 32, 'misc32', 3).setOrigin(0).setDepth(4)
            this.wallsLayer.add(wall)
            this.walls[x][y].wallObjects.push(wall)
        }
        if (this.walls[x][y].bottom) {
            const wall = this.scene.add.sprite(x * 32, y * 32, 'misc32', 2).setOrigin(0).setDepth(4)
            this.wallsLayer.add(wall)
            this.walls[x][y].wallObjects.push(wall)
        }
        if (recomputeNeighbors) {
            if (x > 0) this.updateWall(x - 1, y)
            if (y > 0) this.updateWall(x, y - 1)
            if (x < WORLD_SIZE - 1) this.updateWall(x + 1, y)
            if (y < WORLD_SIZE - 1) this.updateWall(x, y + 1)
        }
    }
}

