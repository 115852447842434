
function numDigits(x: number) {
  /** get number of digits in a number */
  let v = Math.abs(x).toLocaleString('en-US', { maximumFractionDigits: 0 }).replace(/\s/g, '')
  for (let i = 0; i < v.length; i++) {
    v = v.replace(' ', '').replace(',', '')
  }
  return v.length
}



export const roundNumber = (n: number) => {
  /** round big numbers */
  const numberOfDigitBaseValue = n && n < 100 ? 1 : 0
  let value = numberOfDigitBaseValue === 0 ? Math.round(n) : Math.round(n * 10) / 10

  const absValue = Math.abs(value)
  if (absValue >= 1e6) {
    if (absValue < 1e9 && absValue >= 1e6) {
      value = Math.round(value / 1e3) * 1e3
    }
    else if (absValue < 1e12) {
      value = Math.round(value / 1e6) * 1e6
    }
    else if (absValue < 1e15) {
      value = Math.round(value / 1e9) * 1e9
    }
    else if (absValue >= 1e15) {
      const baseN = Math.pow(10, numDigits(value) - 3)
      value = Math.round(value / baseN) * baseN
    }
  }
  return value
}


export const formatNumber = (n: number, options: { round?: boolean, numOfFractionDigits?: number } = { round: false, numOfFractionDigits: 1 }) => {

  /** format numbers */
  if (!n)
    return {
      value: 0,
      formatted: "0",
      formattedNoSuffix: "0",
      suffix: ""
    }

  const value: number = options.round ? roundNumber(n) : n
  const numberOfDigitBaseValue = n && n < 100 ? options.numOfFractionDigits : 0
  let formattedNoSuffix = value.toLocaleString('en-US', { maximumFractionDigits: numberOfDigitBaseValue })
  let suffix = ''
  const absValue = Math.abs(value)

  if (absValue >= 1e6) {
    let _tmp = 0
    if (absValue < 1e9) {
      suffix = 'k'
      _tmp = Math.round(value / 1e3)
      formattedNoSuffix = _tmp.toLocaleString('en-US', { maximumFractionDigits: numberOfDigitBaseValue })
    }
    else if (absValue < 1e12) {
      suffix = 'm'
      _tmp = Math.round(value / 1e6)
      formattedNoSuffix = _tmp.toLocaleString('en-US', { maximumFractionDigits: numberOfDigitBaseValue })
    }
    else if (absValue < 1e15) {
      suffix = 'M'
      _tmp = Math.round(value / 1e9)
      formattedNoSuffix = _tmp.toLocaleString('en-US', { maximumFractionDigits: numberOfDigitBaseValue })
    }
    else {
      suffix = ''
      const baseN = parseInt(Math.pow(10, numDigits(value) - 3).toPrecision(2), 10)
      _tmp = Math.round(value / baseN)
      formattedNoSuffix = value.toPrecision(3)
    }
  }

  return {
    value,
    formatted: `${formattedNoSuffix}${suffix}`,
    formattedNoSuffix,
    suffix
  }
}