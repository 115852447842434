import { Box, Divider, HStack, Heading, Stack, Text, useTheme } from "@chakra-ui/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { FunctionComponent } from "react"
import { dismantle, improve } from "../../../api/world"
import { useGameId, useIsUnlocked, usePlayer, useResources } from "../../../hooks/query"
import { UISpendResources } from "../../../hooks/utils"
import { useStore } from "../../../store"
import { Factions, ResourceType, UnlockType } from "../../../types"
import Button from "../../common/Button"
import GameIcon, { GameIconType } from "../../common/GameIcon"
import HelpText from "../../common/HelpText"
import Progress from "../../common/ProgressWithText"
import { observer } from "mobx-react-lite"

export interface TileImprovementProps {
    faction: Factions
    own: boolean
    allowed: boolean
    visible: boolean
    level: number
    workers: number
    bonus: number
    currentGoalWorkers: number
    goal: number
    canDismantle: boolean
}
export const TileImprovement: FunctionComponent<TileImprovementProps> = ({
    faction,
    allowed,
    level,
    bonus,
    currentGoalWorkers,
    goal,
    workers,
}) => {
    const { mapStore } = useStore()
    const selectedTile = mapStore.selectedTile
    const resources = useResources()

    const isUnlocked = useIsUnlocked(UnlockType.UI_TILE_IMPROVEMENT)
    const gameId = useGameId()

    // send workers
    const work = useMutation({
        mutationFn: (workers: number) => improve(gameId, selectedTile!.x, selectedTile!.y, workers),
        onMutate: (workers) => {
            UISpendResources({ gameId, resources: { workers } })
        },
    })
    const theme = useTheme()
    const color = theme.app.factions[faction]

    const player = usePlayer()
    const ownTile = player?.faction == faction
    return (
        <Box bgColor={color}>
            <HStack alignItems={"center"} justifyContent={"space-between"}>
                <HStack>
                    <GameIcon name={GameIconType.IMPROVEMENT} />
                    <Heading size="md">
                        <HelpText tooltip="Improvements gives you a bonus to this terrain bonuses. Improvements are inactive if the terrain doesn't belong to you.">
                            Improvements
                        </HelpText>
                    </Heading>
                </HStack>
                {level ? <Text fontSize="xs">lvl {level}/4</Text> : null}
            </HStack>
            {level ? <Text>+{bonus}% bonus</Text> : null}
            {level < 4 && allowed && isUnlocked && (
                <>
                    <Progress value={currentGoalWorkers} total={goal}>
                        {currentGoalWorkers} / {goal}
                    </Progress>

                    <HStack gap="0" justifyContent={"center"}>
                        <Button
                            onClick={() => {
                                work.mutate(1)
                            }}
                            isDisabled={resources.workers < 1}
                            margin="0.5"
                            p="2"
                            rightIcon={<GameIcon name={ResourceType.WORKER} />}
                        >
                            +1
                        </Button>
                        <Button
                            onClick={() => {
                                work.mutate(10)
                            }}
                            isDisabled={resources.workers < 10}
                            margin="0.5"
                            p="2"
                            rightIcon={<GameIcon name={ResourceType.WORKER} />}
                        >
                            +10
                        </Button>
                        <Button
                            onClick={() => {
                                work.mutate(100)
                            }}
                            isDisabled={resources.workers < 100}
                            margin="0.5"
                            p="2"
                            rightIcon={<GameIcon name={ResourceType.WORKER} />}
                        >
                            +100
                        </Button>
                    </HStack>
                </>
            )}
        </Box>
    )
}

export const OtherTileImprovement: FunctionComponent<TileImprovementProps> = ({
    faction,
    allowed,
    level,
    bonus,
    currentGoalWorkers,
    goal,
    canDismantle,
}) => {
    const queryClient = useQueryClient()
    const theme = useTheme()
    const { mapStore } = useStore()
    const selectedTile = mapStore.selectedTile
    const color = theme.app.factions[faction]
    const resources = useResources()
    const gameId = useGameId()

    // send soldiers to dismantle
    const dismantleMutation = useMutation({
        mutationFn: (workers: number) => dismantle(gameId, selectedTile!.x, selectedTile!.y, workers, faction),
        onMutate: (workers) => {
            UISpendResources({ gameId, resources: { workers } })
        },
    })

    return (
        <Stack bgColor={color} p="1">
            <HStack alignItems={"center"} justifyContent={"space-between"}>
                <Text>{faction}</Text>
                <Text fontSize="xs">lvl {level}/4</Text>
            </HStack>

            <>
                {level < 4 && (
                    <Progress value={currentGoalWorkers} total={goal} height="15px" fontSize="sm">
                        {currentGoalWorkers} / {goal}
                    </Progress>
                )}
            </>
            {canDismantle && (
                <HStack gap="0" justifyContent={"center"}>
                    <Button
                        onClick={() => {
                            dismantleMutation.mutate(1)
                        }}
                        isDisabled={resources.workers < 1}
                        margin="0.5"
                        p="2"
                        colorScheme="red"
                        size="sm"
                        rightIcon={<GameIcon name={ResourceType.WORKER} />}
                    >
                        +1
                    </Button>
                    <Button
                        onClick={() => {
                            dismantleMutation.mutate(10)
                        }}
                        isDisabled={resources.workers < 10}
                        margin="0.5"
                        p="2"
                        colorScheme="red"
                        size="sm"
                        rightIcon={<GameIcon name={ResourceType.WORKER} />}
                    >
                        +10
                    </Button>
                    <Button
                        onClick={() => {
                            dismantleMutation.mutate(100)
                        }}
                        isDisabled={resources.workers < 100}
                        margin="0.5"
                        p="2"
                        colorScheme="red"
                        size="sm"
                        rightIcon={<GameIcon name={ResourceType.WORKER} />}
                    >
                        +100
                    </Button>
                </HStack>
            )}
        </Stack>
    )
}

export const TileImprovements: FunctionComponent = observer(() => {
    const { mapStore } = useStore()
    const selectedTile = mapStore.selectedTile

    const tileInfo = mapStore.getTileInfo(selectedTile!.x, selectedTile!.y)

    const ownImprovement = tileInfo?.improvements.find((i) => i.faction == tileInfo.faction && i.visible)
    const otherImprovements = tileInfo?.improvements.filter((i) => i.faction != tileInfo.faction && i.visible) || []
    if (!tileInfo || !ownImprovement) return <Box></Box>
    return (
        <Box>
            <TileImprovement {...ownImprovement} />

            {otherImprovements.length > 0 && (
                <>
                    <Divider />
                    <Text>
                        <HelpText tooltip="Improvements made by other factions. They are inactive until these factions get the territory back. You can send workers to dismantle them.">
                            Other improvements
                        </HelpText>
                    </Text>
                </>
            )}
            
            {otherImprovements.map((improvement, i) => (
                <OtherTileImprovement key={i} {...improvement} />
            ))}
        </Box>
    )
})

export default TileImprovement
