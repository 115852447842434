import { Box, BoxProps, keyframes, useStyleConfig } from '@chakra-ui/react'
import { FunctionComponent, useEffect, useState, Fragment, ReactElement } from "react"
import { useIsFirstMount, usePrevious } from '../../hooks/utils'


interface PanelProps extends BoxProps {
  variant?: "badge" | "rounded" | "smooth" | "achievement" | "withBorder"
  animKey?: number
  animated?: boolean
}

const spin = keyframes`
0%{transform:translate(-105%, 0px)}
50%{transform:translate(50%, 0px)}
100%{transform:translate(150%, 0px)}
`


const Panel: FunctionComponent<PanelProps> = ({ variant, children, animKey, animated, ...props }) => {

  // const previousAnimKey = usePrevious(animKey!)
  const [animating, setAnimating] = useState(false)
  const isFirstMount = useIsFirstMount()
  // console.log("previousAnimKey", previousAnimKey)
  // console.log("animKey", animKey)

  useEffect(() => {
    if (animated && animKey && !isFirstMount)
      setAnimating(true)
  }, [animKey])
  const styles = useStyleConfig('Panel', { variant })
  // if (animKey) {
  //   console.log("-------------------------------------------")
  //   console.log("previousAnimKey", previousAnimKey)
  //   console.log("animKey", animKey)
  // }
  // const runAnimation = animated && previousAnimKey && previousAnimKey !== animKey



  // Pass the computed styles into the `__css` prop
  // 
  let Wrapper:any = Fragment
  let WrapperProps:any = {}
  if(animated){
    Wrapper = Box
    WrapperProps = {
      position: "relative",
    }
  }

  return <Box
    __css={styles}
    position="relative"
    overflow={animated ? "hidden": "visible"}
    {...props}
  >
    {animating && <Box
      overflow="hidden"
      borderRadius="sm"
      margin={1}
      top="0"
      bottom="0"
      left="0"
      right="0"
      position="absolute"
    >
      <Box animation={`${spin} 1 1s linear`} width="full" height="full" onAnimationEnd={() => (setAnimating(false))}
        background="linear-gradient(-100deg, var(--chakra-colors-orange-500) 40%, #ffffff 50%, var(--chakra-colors-orange-500)) 50%"
        transform="translate(-105%, 0)" />
    </Box>}
    <Wrapper {...WrapperProps}>
      {children}
    </Wrapper>
  </Box>
}


export default Panel