import { CloseIcon } from "@chakra-ui/icons"
import { Box, forwardRef, HStack, IconButton, Image, Stack, Text } from "@chakra-ui/react"
import { AnimatePresence } from "framer-motion"
import { AnimatedBox } from "../common/Motion"
import { observer } from "mobx-react-lite"
import { useStore } from "../../store"
import { Notification } from "../../store/SocketStore"
import { useTranslation } from "react-i18next"
import { useHQInfo } from "../../hooks/query"

export const NotificationsComponent = observer(() => {
    // show all unlocks

    const { socketStore } = useStore()

    return (
        <Box pos={"fixed"} bottom={50} right="50%" left="50%" transform="translate(-50%, 0)" width="300px">
            <AnimatePresence mode="popLayout">
                {socketStore.notifications.map((notification) => {
                    return <NotificationBox key={notification.id} {...notification} />
                })}
            </AnimatePresence>
        </Box>
    )
})


const NotificationBox = forwardRef<any, any>(({ id, title, message, type, faction }: Notification, ref) => {
    const { socketStore } = useStore()
    const { data: hqInfo } = useHQInfo()
    const { t } = useTranslation()
    let is_building = false
    let ttitle = t(`notification.${title}.title`)
    let text = message
    let bgColor = "teal.600"
    // don't edit string if this is already a translate string
    if (title.indexOf(".") > -1) {
        ttitle = t(title)
    }
    if (type === "unlock" && !title.startsWith("UI_")) {
        is_building = true
        ttitle = t(`buildings.${title}.title`)
        text = "You've unlocked a new building!"
    } else if (type === "achievement") {
        ttitle = t(`achievements.${title}.title`)
        text = "You've earned an achievement!"
        bgColor = "orange.600"
    } else if (type === "unlock") {
        text = "You've unlocked something!"
    } else if (type === "castle") {
        if (hqInfo?.hq.faction === faction) {
            ttitle = t(`notification.castle.winning.title`)
            text = t(`notification.castle.winning.text`)
            bgColor = "green.600"
        } else {
            ttitle = t(`notification.castle.losing.title`)
            text = t(`notification.castle.losing.text`)
            bgColor = "red.600"
        }
    } else if (type === "new_game") {
        ttitle = t(`notification.new_game.title`)
    } else if (type === "game_completed") {
        ttitle = t(`notification.game_completed.title`)
    } else if (type === "event_start") {
        ttitle = t(`events.${title}.title`)
        text = t(`events.${title}.notification`)
    }


    return (
        <AnimatedBox
            ref={ref}
            bgColor={bgColor}
            border="4px solid"
            borderColor="orange"
            borderStyle="outset"
            p={is_building ? 0 : 4}
            my="2"
            pos="relative"
            layout
            initial={{ scale: 0.6, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.6, opacity: 0 }}
            transition={{ type: "ease" }}
            onClick={() => {
                socketStore.markAsRead(id)
            }}
        >
            <HStack>
                {is_building && <Image src={`/images/buildings/${title.toLowerCase()}.png`} maxWidth={"80px"} />}
                <Stack py="1">
                    <Text fontSize="sm">{ttitle}</Text>
                    <Text fontSize={"xs"}>{text}</Text>
                </Stack>
            </HStack>
            {/* <IconButton
                aria-label="close"
                size="xs"
                icon={<CloseIcon />}
                position="absolute"
                right="-1"
                top="-1"
                
            /> */}
        </AnimatedBox>
    )
})
