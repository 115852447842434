import { Box, HStack, Heading, Stack, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react"
import { FunctionComponent, useEffect } from "react"
import Loading from "../components/common/Loading"
import { useConfig, useGame, usePlayer } from "../hooks/query"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import Button from "../components/common/Button"
import { useNavigate } from "react-router"
import { GameMode, GameModeInfo, GameStatus, getGames, joinGame } from "../api/games"
import { getGroup } from "../api/player"
import { MAX_PLAYERS_IN_GROUP } from "../config"
import Link from "../components/common/Link"
import HelpText from "../components/common/HelpText"
import { useStore } from "../store"

const GamesPage: FunctionComponent = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const { userStore } = useStore()

    const { data: games, error } = useQuery({
        queryKey: ["games"],
        queryFn: getGames,
        staleTime: 60 * 1000,
    })

    // we check here if the token is expired. If so, we cleanup the apiToken and redirect to the login page
    useEffect(() => {
        if (error && (error as any).response.status == 401) {
            console.log("error", error)
            userStore.logout()
        }
    }, [error])

    const joinMutation = useMutation({
        mutationFn: (gameId: number) => joinGame(gameId),
        onSuccess: (params, gameId) => {
            if (params.status == "error") {
                throw new Error("Can't join")
            }
            const game = games?.find((game) => game.id == gameId)
            queryClient.invalidateQueries({ queryKey: ["games"] })
            if (game!.status == GameStatus.PLAYING) navigate(`/play/${gameId}`)
            else navigate(`/games/${gameId}`)
        },
    })

    const { data: group, isPending } = useQuery({
        queryKey: ["group"],
        queryFn: getGroup,
        staleTime: 0,
    })
    const isLeader = group?.isLeader
    const inGroup = group?.id
    const groupSize = group?.members.length || 1

    const participatings = games?.filter((game) => game.participating) || []
    const inLobby = participatings.find((game) => game.status == GameStatus.LOBBY)



    if (!games) return <Loading />

    return (
        <Box p="4">
            <Heading as="h1">Games list</Heading>

            {/* <Box layerStyle={"error"} m="2">
                <Text>
                    No games are currently opened or planned. Please{" "}
                    <Link href="https://discord.gg/DsKSmetnqV" target="_blank" color="orange.100">
                        join the Discord
                    </Link>{" "}
                    to be kept informed.
                </Text>
            </Box> */}

            {joinMutation.error && <Box layerStyle={"error"}>{joinMutation.error.message}</Box>}

            {participatings.length > 0 && (
                <Box>
                    <Text>
                        You&apos;re already registered in {participatings.length} game{participatings.length > 1 ? "s" : ""}{" "}
                        :
                    </Text>

                    {participatings.map((game) => (
                        <HStack key={game.id}>
                            <Text key={game.id}>#{game.id}</Text>
                            {game.status == GameStatus.PLAYING && (
                                <Button onClick={() => navigate(`/play/${game.id}`)}>Join</Button>
                            )}
                            {game.status == GameStatus.LOBBY && (
                                <Button onClick={() => navigate(`/games/${game.id}`)}>Go to lobby</Button>
                            )}
                        </HStack>
                    ))}
                </Box>
            )}

            <Box overflowX="auto">
                <Table size="sm">
                    <Thead>
                        <Tr>
                            <Th>Game</Th>
                            <Th>Status</Th>
                            <Th>Actions</Th>
                            <Th>Mode</Th>
                            <Th>Map</Th>
                            <Th>Players</Th>

                        </Tr>
                    </Thead>

                    <Tbody>
                        {games.map((game) => {
                            const enoughPlace = groupSize <= game.maxGroupSize || game.canJoinAfterStart
                            return (
                                <Tr key={game.id}>
                                    <Td>
                                        <Text>
                                            <Link to={`/games/${game.id}`} color="orange.100">
                                                #{game.id}
                                            </Link>
                                        </Text>
                                    </Td>
                                    <Td>
                                        <Text>{game.status}</Text>
                                    </Td>
                                    <Td>
                                        {game.participating ? (
                                            <>
                                                <Text>Already in this game</Text>
                                                {game.status == GameStatus.PLAYING && (
                                                    <Button
                                                        colorScheme="blue"
                                                        onClick={() => navigate(`/play/${game.id}`)}
                                                    >
                                                        Play
                                                    </Button>
                                                )}
                                                {game.status == GameStatus.LOBBY && (
                                                    <Button
                                                        colorScheme="blue"
                                                        onClick={() => navigate(`/games/${game.id}`)}
                                                    >
                                                        Go to lobby
                                                    </Button>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {game.isOpen && (enoughPlace || game.canJoinAfterStart) && (
                                                    <>
                                                        {inLobby && game.status == GameStatus.LOBBY && (
                                                            <Box layerStyle="warning">
                                                                You will leave your other lobby
                                                            </Box>
                                                        )}

                                                        <Button
                                                            onClick={() => {
                                                                joinMutation.mutate(game.id)
                                                            }}
                                                            isDisabled={joinMutation.isPending}
                                                            isLoading={joinMutation.isPending}
                                                        >
                                                            Join {isLeader ? "(with your group)" : ""}
                                                        </Button>
                                                    </>
                                                )}
                                                {game.isOpen && !enoughPlace && (
                                                    <Box layerStyle="warning">Not enough place for your group</Box>
                                                )}
                                            </>
                                        )}
                                    </Td>
                                    <Td>
                                        <HelpText tooltip={<Text>{GameModeInfo[game.type].description}</Text>}>
                                            {GameModeInfo[game.type].name}
                                        </HelpText>
                                    </Td>
                                    <Td>
                                        <Text>{game.map}</Text>
                                    </Td>
                                    <Td>
                                        <Stack>
                                            <Text>
                                                {(game.canJoinAfterStart && game.numberOfPlayers < game.maxPlayers) ||
                                                    !game.canJoinAfterStart ? (
                                                    <>
                                                        {game.numberOfPlayers}/{game.maxPlayers}
                                                    </>
                                                ) : (
                                                    <>{game.numberOfPlayers}</>
                                                )}
                                            </Text>
                                            {game.status === GameStatus.LOBBY &&
                                                game.maxGroupSize < MAX_PLAYERS_IN_GROUP && (
                                                    <Box layerStyle={"warning"}>
                                                        <Text>(max group size : {game.maxGroupSize})</Text>
                                                    </Box>
                                                )}
                                        </Stack>
                                    </Td>

                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
            </Box>
        </Box>
    )
}

export default GamesPage
