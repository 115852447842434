// import { Box, Heading, Text } from "@chakra-ui/react"
// import { observer } from "mobx-react-lite"
// import { FunctionComponent } from "react"
// import Activities from "../activities/Activities"
// import { useStore } from "../../store"

// const BattleLog: FunctionComponent = observer(() => {
//     const { socketStore } = useStore()
//     return (
//         <Box minHeight="200px" maxHeight="100%" w="full" bgColor={"orange.500"}>
//             <Heading
//                 size="sm"
//                 mb={2}
//                 borderBottom="1px solid"
//                 borderColor="orange.200"
//                 position="sticky"
//                 top="0"
//                 zIndex="1"
//                 bgColor={"orange.600"}
//             >
//                 <Text p="2">Battle log</Text>
//             </Heading>
//             <Activities
//                 list={socketStore.battleActivities}
//                 maxHeight="calc(100% - 42px)"
//                 bgColor={"orange.500"}
//                 initialized={socketStore.battleActivitiesInitialized}
//             />
//         </Box>
//     )
// })

// export default BattleLog

import {
    Box,
    Button,
    HStack,
    Heading,
    Menu,
    MenuButton,
    MenuItem,
    MenuItemOption,
    MenuList,
    MenuOptionGroup,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
} from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { FunctionComponent } from "react"
import Activities from "../activities/Activities"
import { useStore } from "../../store"
import { useQuery } from "@tanstack/react-query"
import { useGameId } from "../../hooks/query"
import { getCaseActivities } from "../../api/world"
import { ChevronDownIcon } from "@chakra-ui/icons"
import { MdFilterAlt } from "react-icons/md"
import { PlayerActivity, PlayerActivityType } from "../../api/player"
import SettingsStore from "../../store/SettingsStore"

const typeToActivities = {
    military: [
        PlayerActivityType.LOOT,
        PlayerActivityType.SOLDIERS_ATTACK,
        PlayerActivityType.SOLDIERS_DEFEND,
        PlayerActivityType.SUPPORT_SENT,
    ],
    workers: [
        PlayerActivityType.DISMANTLE_WORKERS_SENT,
        PlayerActivityType.IMPROVEMENT_WORKERS_SENT,
        PlayerActivityType.FORTIFICATION_WORKERS_SENT,
    ],
}

const filterActivities = (activities: PlayerActivity[], type: "all" | "military" | "workers", quantity: number) => {
    const allowedActivities = type !== "all" ? typeToActivities[type as keyof typeof typeToActivities] : null
    const minimumAmount = quantity
    const filteredActivities = activities?.filter(
        (a) =>
            (allowedActivities === null || (allowedActivities && allowedActivities.includes(a.type))) &&
            (a.amount === null || a.amount! >= minimumAmount || a.data.captured==true)
    )
    return filteredActivities
}

const CaseActivities: FunctionComponent = observer(() => {
    const { mapStore, settingsStore } = useStore()
    const gameId = useGameId()
    const { data: activities, isLoading } = useQuery({
        queryKey: ["caseActivities", mapStore.selectedTile?.x, mapStore.selectedTile?.y],
        queryFn: () => getCaseActivities(gameId, mapStore.selectedTile!.x, mapStore.selectedTile!.y),
        enabled: mapStore.selectedTile?.x !== undefined && mapStore.selectedTile?.y !== undefined,
    })
    const filteredActivities = filterActivities(
        activities ?? [],
        settingsStore.battleLogType,
        settingsStore.battleLogQuantity
    )
    return <Activities list={filteredActivities ?? []} maxHeight="calc(100% - 42px)" initialized={!isLoading} />
})

const BattleLog: FunctionComponent = observer(() => {
    const { socketStore, mapStore, settingsStore } = useStore()

    const filteredActivities = filterActivities(
        socketStore.battleActivities ?? [],
        settingsStore.battleLogType,
        settingsStore.battleLogQuantity
    )
    return (
        <Box id="battle-log" minHeight="100px" maxHeight="100%" w="full" bgColor={"orange.500"} p="1">
            <Tabs isLazy height="100%" overflowY="hidden">
                <HStack justify="space-between">
                    <TabList>
                        <Tab>All logs</Tab>
                        {mapStore.selectedTile && <Tab>Ter. logs</Tab>}
                    </TabList>
                    <Box>
                        <Menu size="sm">
                            <MenuButton as={Button} size="sm">
                                <MdFilterAlt />
                            </MenuButton>
                            <MenuList>
                                <MenuOptionGroup
                                    defaultValue={"" + settingsStore.battleLogQuantity}
                                    title="Quantity"
                                    type="radio"
                                    onChange={(value) =>
                                        settingsStore.setBattleLogQuantity(parseInt(value as string, 10))
                                    }
                                >
                                    <MenuItemOption value={"1"}>All</MenuItemOption>
                                    <MenuItemOption value={"5"}>{">"} 5</MenuItemOption>
                                    <MenuItemOption value={"50"}>{">"} 50</MenuItemOption>
                                </MenuOptionGroup>
                                <MenuOptionGroup
                                    defaultValue={settingsStore.battleLogType}
                                    title="Type"
                                    type="radio"
                                    onChange={(value) =>
                                        settingsStore.setBattleLogType(value as "all" | "military" | "workers")
                                    }
                                >
                                    <MenuItemOption value="all">All</MenuItemOption>
                                    <MenuItemOption value="military">Military</MenuItemOption>
                                    <MenuItemOption value="workers">Workers</MenuItemOption>
                                </MenuOptionGroup>
                            </MenuList>
                        </Menu>
                    </Box>
                </HStack>

                <TabPanels height="100%" p="0">
                    <TabPanel height="100%" p="0">
                        <Activities
                            list={filteredActivities}
                            maxHeight="calc(100% - 42px)"
                            bgColor={"orange.500"}
                            initialized={socketStore.battleActivitiesInitialized}
                        />
                    </TabPanel>
                    {mapStore.selectedTile && (
                        <TabPanel height="100%" p="0">
                            <CaseActivities />
                        </TabPanel>
                    )}
                </TabPanels>
            </Tabs>

            {/* <Heading
                size="sm"
                mb={2}
                // borderBottom="1px solid"
                // borderColor="orange.200"
                position="sticky"
                top="0"
                zIndex="1"
                bgColor={"orange.600"}
            >
                <Text p="2">Battle log</Text>
            </Heading> */}
        </Box>
    )
})

export default BattleLog
