import {
    Box,
    chakra,
    Collapse,
    HStack,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text,
    useDisclosure,
    useRadio,
    useRadioGroup,
} from "@chakra-ui/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { FunctionComponent } from "react"
import { build } from "../../api/game"
import { useConfig, useGameId, useHQInfo } from "../../hooks/query"
import { useHasEnoughResources, UISpendResources } from "../../hooks/utils"
import { BuildingType } from "../../types"
import ModList from "../resources/ModList"
import Resources from "../resources/Resources"
import Button from "../common/Button"
import { TbHammer } from "react-icons/tb"
import { useTranslation } from "react-i18next"

type BuildModalProps = {
    inactive?: boolean
}
const BuildModal: FunctionComponent<BuildModalProps> = ({ inactive = false }) => {
    const queryClient = useQueryClient()
    const gameId = useGameId()
    const { data: hqInfo } = useHQInfo()
    const { t } = useTranslation()
    const unlocks = hqInfo?.unlocks || []
    const { isOpen, onOpen, onClose } = useDisclosure()

    const config = useConfig()

    const onCloseModal = () => {
        onClose()
        setValue("")
    }

    const { value, getRadioProps, getRootProps, setValue } = useRadioGroup()

    const mutation = useMutation({
        mutationFn: () => build(gameId, value as BuildingType),
        onMutate: () => {
            const buildingInfo = config?.buildings.find((building) => building.name == value)
            if (!buildingInfo) return
            UISpendResources({
                gameId,
                resources: buildingInfo.cost,
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["hqInfo", gameId] })
            queryClient.invalidateQueries({ queryKey: ["effects", gameId] })
            queryClient.invalidateQueries({ queryKey: ["unlocks", gameId] })
            onCloseModal()
        },
    })

    const existingBuildings = hqInfo?.buildings.map((building) => building.name)

    const buildings =
        config?.buildings.filter((building) => {
            if (building.requires && !unlocks.includes(building.requires)) return false
            if (building.unique && existingBuildings?.includes(building.name)) return false
            return true
        }) || []
    // sort by hq level
    buildings.sort((a, b) => a.hq - b.hq)

    const buildingInfo = config?.buildings.find((building) => building.name == value)
    const enoughResources = useHasEnoughResources(buildingInfo?.cost || {})
    const enoughHQ = (buildingInfo?.hq || 0) <= (hqInfo?.hq.level || 0)

    return (
        <>
            <Button
                onClick={onOpen}
                leftIcon={<TbHammer />}
                title={"Build"}
                colorScheme={inactive ? "blue" : "green"}
                size={inactive ? "sm" : "lg"}
            >
                {inactive ? "Show buildings" : "Build"}
            </Button>

            <Modal isOpen={isOpen} onClose={onCloseModal} scrollBehavior="inside" size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>New building</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box>
                            <Stack {...getRootProps()} spacing={0}>
                                {buildings.map((building) => (
                                    <BuildBuilding
                                        name={building.name}
                                        key={building.name}
                                        selected={value === building.name}
                                        radioProps={getRadioProps({
                                            value: building.name,
                                        })}
                                    />
                                ))}
                            </Stack>
                        </Box>
                    </ModalBody>

                    <ModalFooter>
                        {!inactive && (
                            <Button
                                mr={3}
                                onClick={() => mutation.mutate()}
                                isLoading={mutation.isPending}
                                isDisabled={!value || !enoughResources || !enoughHQ || mutation.isPending}
                            >
                                Build
                            </Button>
                        )}
                        <Button onClick={onCloseModal} colorScheme="red">
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

interface BuildingProps {
    name: BuildingType
    radioProps: any
    selected: boolean
}
const BuildBuilding: FunctionComponent<BuildingProps> = ({ name, radioProps, selected }) => {
    const { data: hqInfo } = useHQInfo()
    const config = useConfig()
    const buildingInfo = config?.buildings.find((building) => building.name == name)
    const { state, getInputProps, getCheckboxProps, htmlProps, getLabelProps } = useRadio(radioProps)
    const { t } = useTranslation()
    if (!buildingInfo) return <Text>Invalid building</Text>
    const buildable = buildingInfo.hq <= (hqInfo?.hq.level || 0)

    return (
        <chakra.label {...htmlProps} cursor="pointer">
            <input {...getInputProps({})} hidden />
            <Box
                {...getCheckboxProps()}
                bg={state.isChecked ? "orange.700" : "transparent"}
                p={4}
                borderBottom="1px solid"
                borderColor="orange.800"
            >
                <HStack justifyContent={"space-between"}>
                    <Stack gap="0">
                        <Text fontSize="2xl">{t(`buildings.${buildingInfo.name}.title`)}</Text>
                        {!buildable && <Text variant="negative">Requires a level {buildingInfo.hq} village</Text>}
                    </Stack>

                    <Resources {...buildingInfo.cost} />
                </HStack>

                {buildable && (
                    <Collapse animateOpacity in={selected}>
                        <SelectedBuilding name={buildingInfo.name} />
                    </Collapse>
                )}
            </Box>
        </chakra.label>
    )
}

interface SelectedBuildingProps {
    name: BuildingType
}
const SelectedBuilding: FunctionComponent<SelectedBuildingProps> = ({ name }) => {
    const config = useConfig()
    const buildingInfo = config?.buildings.find((building) => building.name == name)

    const effects = buildingInfo?.baseEffects
    return (
        <Stack>
            <HStack w="full" alignItems={"start"}>
                <Image
                    src={`/images/buildings/${name.toLowerCase()}.png`}
                    border="4px solid"
                    borderColor="orange"
                    mb="2"
                    borderStyle={"inset"}
                    maxWidth={"120px"}
                />
                <Stack>
                    {name === BuildingType.COMMAND_CENTER && (
                        <Text color="green.100" fontWeight={"bold"}>
                            Unlocks specializations
                        </Text>
                    )}
                    {effects && <ModList value={effects} />}
                </Stack>
            </HStack>
            {buildingInfo?.unique && (
                <Text fontSize="sm" color="orange.400">
                    Unique building, undestructible.
                </Text>
            )}
        </Stack>
    )
}

export default BuildModal
