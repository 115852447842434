import { Box, Flex, HStack, Stack, Text, Tooltip } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { FunctionComponent, memo } from "react"
import { Effect } from "../../api/game"
import { useConfig, useEffects, useHQInfo, useIsUnlocked, useResources } from "../../hooks/query"
import { useIsMobile } from "../../hooks/utils"
import { useStore } from "../../store"
import { ResourceType, UnlockType } from "../../types"
import GameIcon from "../common/GameIcon"
import Number from "../common/Number"

interface CurrentResourcesProps {
    collapsable?: boolean
    simple?: boolean
}
const CurrentResources: FunctionComponent<CurrentResourcesProps> = ({ collapsable = false, simple = false }) => {
    const { wood, iron, soldiers, workers, guardian, knight } = useResources()
    const showSoldier = useIsUnlocked(UnlockType.UI_WORLD)
    const showGuardian = useIsUnlocked(UnlockType.UI_GUARDIAN)
    const showKnight = useIsUnlocked(UnlockType.UI_KNIGHT)
    const showWorker = useIsUnlocked(UnlockType.UI_PROJECTS)
    const { data: effects } = useEffects()
    const isMobile = useIsMobile()
    const { data: hq } = useHQInfo()
    const sleepMode = hq?.hq.sleepMode

    return (
        <Box
            display="flex"
            justifyContent={collapsable ? "flex-start" : "center"}
            w="100%"
            gap="1"
            pb="1"
            flexWrap={"wrap"}
            flexDirection={isMobile && !simple ? "column" : "row"}
        >
            {wood !== undefined && effects && (
                <>
                    <CurrentResource
                        name={ResourceType.WOOD}
                        value={wood}
                        production={effects.production.wood!}
                        storage={effects.storage.wood!}
                        collapsable={collapsable}
                        simple={simple}
                        showProduction={!isMobile || collapsable}
                        sleepMode={sleepMode}
                    />
                    <CurrentResource
                        name={ResourceType.IRON}
                        value={iron}
                        production={effects?.production.iron}
                        storage={effects?.storage.iron}
                        collapsable={collapsable}
                        simple={simple}
                        showProduction={!isMobile || collapsable}
                        sleepMode={sleepMode}
                    />

                    {showWorker && (
                        <CurrentResource
                            name={ResourceType.WORKER}
                            value={workers}
                            production={effects?.production.workers}
                            storage={effects?.storage.workers}
                            collapsable={collapsable}
                            simple={simple}
                            showProduction={!isMobile || collapsable}
                            sleepMode={sleepMode}
                        />
                    )}
                    {showSoldier && (
                        <CurrentResource
                            name={ResourceType.SOLDIER}
                            value={soldiers}
                            production={effects?.production.soldiers}
                            storage={effects?.storage.soldiers}
                            collapsable={collapsable}
                            simple={simple}
                            showProduction={!isMobile || collapsable}
                            sleepMode={sleepMode}
                        />
                    )}
                    {showGuardian && (
                        <CurrentResource
                            name={ResourceType.GUARDIAN}
                            value={guardian}
                            percentage={true}
                            production={effects?.production.guardian}
                            unique={true}
                            collapsable={collapsable}
                            simple={simple}
                            showProduction={!isMobile || collapsable}
                            sleepMode={sleepMode}
                        />
                    )}
                    {showKnight && (
                        <CurrentResource
                            name={ResourceType.KNIGHT}
                            value={knight}
                            percentage={true}
                            production={effects?.production.knight}
                            unique={true}
                            collapsable={collapsable}
                            simple={simple}
                            showProduction={!isMobile || collapsable}
                            sleepMode={sleepMode}
                        />
                    )}
                </>
            )}
        </Box>
    )
}

interface CurrentResourceProps {
    name: ResourceType
    value: number
    production: Effect
    storage?: Effect
    unique?: boolean
    collapsable?: boolean
    simple?: boolean
    percentage?: boolean
    showProduction?: boolean
    sleepMode?: boolean
}

const minutesToTime = (minutes: number) => {
    const hours = Math.floor(minutes / 60)
    const remainingMinutes = (minutes % 60).toFixed(0)
    // add leading 0
    if (remainingMinutes.length == 1) {
        return `${hours}h0${remainingMinutes}m`
    }
    return `${hours}h${remainingMinutes}m`
}

interface SupportIconProps {
    name: ResourceType
}
const SupportIcon: FunctionComponent<SupportIconProps> = observer(({ name }) => {
    const { mapStore } = useStore()
    const position = mapStore.getCurrentSupportPosition()

    const onClick = () => {
        if (position) mapStore.setSelectedTile(position.x, position.y)
    }

    return (
        <Tooltip label={position ? "Active on the map" : "Inactive"}>
            <Box cursor={"pointer"}>
                <GameIcon name={name} scale={1.5} onClick={onClick} inactive={!position} />
            </Box>
        </Tooltip>
    )
})

const CurrentResource: FunctionComponent<CurrentResourceProps> = memo(
    ({
        name,
        value,
        production,
        storage,
        collapsable = false,
        simple = false,
        unique = false,
        percentage = false,
        showProduction = true,
        sleepMode = false,
    }: CurrentResourceProps) => {
        // const { isOpen, onToggle } = useDisclosure()

        // const format = (value: number) => {
        //     return value.toFixed(1)
        // }
        const { data: hq } = useHQInfo()
        const { ticks } = useResources()
        const fullValue = unique ? 100 : storage?.total || 0
        const isFull = value >= fullValue
        const numOfFractionDigits = ["soldiers", "workers"].includes(name) ? 2 : 1
        const config = useConfig()
        const tickDuration = config?.modeConfig.tick_duration || 20

        const ticksSpeed = Math.min(hq?.hq.ticksSpeed || 20, ticks + 1)
        const currentProduction = ticksSpeed * production.total
        const timeBeforeFull = currentProduction ? Math.max(fullValue - value, 0) / (currentProduction * 60 / tickDuration) : 0

        if (simple) {
            return (
                <HStack
                    border="1px solid"
                    borderColor="orange.900"
                    px="1"
                    bgColor="orange.500"
                    borderRadius={"sm"}
                    boxShadow="md"
                >
                    <HStack fontSize="sm" alignItems={"center"} gap="0">
                        {unique ? <SupportIcon name={name} /> : <GameIcon name={name} scale={1.5} />}
                        <Number value={value} animated percentage={percentage} color={isFull ? "red.700" : "auto"} floor={true} />
                    </HStack>

                    {showProduction && !sleepMode && (
                        <Text fontSize="xs">
                            {" "}
                            (+
                            <Number value={currentProduction} numOfFractionDigits={numOfFractionDigits} animated />)
                        </Text>
                    )}
                </HStack>
            )
        }
        if (!collapsable) {
            return (
                <Box
                    border="1px solid"
                    borderColor="orange.900"
                    p="1"
                    bgColor="orange.500"
                    borderRadius={"sm"}
                    boxShadow={"md"}
                >
                    <Flex alignItems={"center"}>
                        <Box p="2">{unique ? <SupportIcon name={name} /> : <GameIcon name={name} scale={1.5} />}</Box>

                        <Stack gap="0" justifyContent={"center"} alignItems={"center"}>
                            <Box>
                                <Number
                                    value={value}
                                    animated
                                    percentage={percentage}
                                    color={isFull ? "red.700" : "auto"}
                                    floor={true}
                                />

                                {storage && (
                                    <Text size="sm" as="span">
                                        /
                                        <Number fontSize="sm" animated value={storage.total} />
                                    </Text>
                                )}
                            </Box>

                            {!sleepMode && (
                                <Text fontSize="sm">
                                    <Number
                                        value={currentProduction}
                                        showPlus
                                        numOfFractionDigits={numOfFractionDigits}
                                        animated
                                    />
                                    /<Tooltip label="Time before full">{minutesToTime(timeBeforeFull)}</Tooltip>
                                </Text>
                            )}
                        </Stack>
                    </Flex>
                </Box>
            )
        } else {
            return (
                <Box>
                    {/* <Button variant="ghost" onClick={onToggle}> */}
                    <Text>
                        <HStack alignItems={"center"}>
                            <GameIcon name={name} />
                            <Box>
                                <Stack gap={0}>
                                    <Box>
                                        <Number value={value} fontSize="xl" percentage={percentage} floor={true} />

                                        {storage && (
                                            <>
                                                {" "}
                                                / <Number value={storage.total} />
                                            </>
                                        )}
                                    </Box>

                                    {showProduction && !sleepMode && (
                                        <Box>
                                            (
                                            <Number
                                                value={currentProduction}
                                                showPlus
                                                numOfFractionDigits={numOfFractionDigits}
                                            />
                                            /<Tooltip label="Time before full">{minutesToTime(timeBeforeFull)}</Tooltip>
                                            )
                                        </Box>
                                    )}
                                </Stack>
                            </Box>
                        </HStack>
                    </Text>
                </Box>
            )
        }
    },
    (prev, next) => {
        return prev.name === next.name && prev.value === next.value && prev.production.total === next.production.total && prev.storage?.total === next.storage?.total && prev.unique === next.unique && prev.collapsable === next.collapsable && prev.simple === next.simple && prev.percentage === next.percentage && prev.showProduction === next.showProduction && prev.sleepMode === next.sleepMode
    }
)

CurrentResource.displayName = "CurrentResource"

export default CurrentResources
