import { Box, Button, Collapse, HStack, Icon, IconButton, Stack, Text } from "@chakra-ui/react"
import { FunctionComponent, useState } from "react"
import { useGame, useHQInfo } from "../../../hooks/query"
import DateComponent from "../../common/DateComponent"
import { IoMdArrowDropdown } from "react-icons/io"

const Victory: FunctionComponent = () => {
    const [show, setShow] = useState(false)
    const handleToggle = () => setShow(!show)

    const { data } = useHQInfo()
    const { data: game } = useGame()
    const faction = data?.hq.faction
    const winning = game?.winning
    const winner = faction === winning
    // get window width
    const showIcon = window.innerWidth < 1400
    return (
        <HStack
            w="full"
            bgColor={winner ? "green.600" : "red.600"}
            px="1"
            alignItems={"center"}
            justifyContent={"center"}
            onClick={handleToggle}
        >
            <Collapse in={show} startingHeight={26}>
                {faction === winning && (
                    <Text>
                        Your faction has captured the castle. Hold it until <DateComponent date={game!.winningDate} /> and
                        you&apos;ll win.
                    </Text>
                )}
                {faction !== winning && (
                    <Text>
                        {winning} faction has captured the castle. Take it back (at least 1 tile) before{" "}
                        <DateComponent date={game!.winningDate} /> or you&apos;ll lose.
                    </Text>
                )}
            </Collapse>
            {showIcon && <Icon aria-label="Show more" as={IoMdArrowDropdown} />}
        </HStack>
    )
}

export default Victory
