import { WORLD_SIZE } from "../config"

// check content for links and wrap them in a tag
export const wrapLinks = (content: string) => {
    const regex = /(https?:\/\/[^\s]+)/g
    return content.replace(regex, (match) => `<a href="${match}" target="_blank">${match}</a>`)
}

// wrap xx:yy coordinates in a span
export const wrapCoordinates = (content: string) => {
    const regex = /(\d{1,2}:\d{1,2})/g

    return content.replace(regex, (match) => {
        const [x, y] = match.split(":")
        // check if x and y are int
        if (Number.isInteger(parseInt(x)) && Number.isInteger(parseInt(y))) {
            const xn = parseInt(x)
            const yn = parseInt(y)
            if (xn >= WORLD_SIZE || yn >= WORLD_SIZE || xn < 0 || yn < 0) return match
            return `<span class="coordinates" data-x="${x}" data-y="${y}">${match}</span>`
        } else {
            return match
        }
    })
}