import { SimpleGrid } from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { FunctionComponent } from "react"
import { LeaderRole } from "../../api/faction"
import { useFactions, useGameId } from "../../hooks/query"
import LeaderMessage from "./LeaderMessage"

const LeaderMessages: FunctionComponent = () => {
    const gameId = useGameId()
    const queryClient = useQueryClient()
    const { data: faction } = useFactions()
    const ownFaction = faction?.find((f) => f.my_faction)

    return (
        <SimpleGrid p="2" columns={[1, 1, 2, 3]}>
            <LeaderMessage leaderRole={LeaderRole.Leader} message={ownFaction?.details?.messages.leader?.message || ""} updatedAt={ownFaction?.details?.messages.leader?.updated_at || ""} />
            <LeaderMessage leaderRole={LeaderRole.General} message={ownFaction?.details?.messages.general?.message || ""} updatedAt={ownFaction?.details?.messages.general?.updated_at || ""} />
            <LeaderMessage leaderRole={LeaderRole.Diplomat} message={ownFaction?.details?.messages.diplomat?.message || ""} updatedAt={ownFaction?.details?.messages.diplomat?.updated_at || ""} />
        </SimpleGrid>
    )
}

export default LeaderMessages
