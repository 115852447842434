import { Box, Button, Text } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import Phaser from "phaser"
import { FunctionComponent, useEffect } from "react"
import { useConfig, useInvasionInfo } from "../../hooks/query"
import { useStore } from "../../store"
import SelectedTile from "./SelectedTile"

let game: Phaser.Game | null

// tilemap with layer : https://phaser.io/examples/v2/tilemaps/blank-tilemap

const AccessibleMap: FunctionComponent = observer(() => {

    const { mapStore, socketStore } = useStore()
    const worldConfig = useConfig()
    const { data: invasionInfo } = useInvasionInfo()


    const arrowListener = (event: KeyboardEvent) => {
        if (event.key === "ArrowUp") {
            mapStore.move(0, -1)
        }
        if (event.key === "ArrowDown") {
            mapStore.move(0, +1)
        }
        if (event.key === "ArrowLeft") {
            mapStore.move(-1, 0)
        }
        if (event.key === "ArrowRight") {
            mapStore.move(1, 0)
        }
    }
    useEffect(() => {
        mapStore.setSelectedTile(5, 5)
        window.addEventListener("keyup", arrowListener)
        return () => {
            window.removeEventListener("keyup", arrowListener)
        }
    }, [])
    
    const select = (xoffset:number, yoffset:number) => {
        if(!mapStore.selectedTile)
            mapStore.setSelectedTile(5, 5)
        mapStore.setSelectedTile(mapStore.selectedTile!.x + xoffset, mapStore.selectedTile!.y + yoffset)
    }
    return (
        <Box>
            <Box layerStyle={"info"} aria-hidden="true">
                <Text>Warning, you are in accessibility mode. If it&apos;s not what you want, go to settings to disable it.</Text>
            </Box>
            
            <Box>
                <Button onClick={() => select(0, 1)}>Up</Button>
                <Button onClick={() => select(0, -1)}>Down</Button>
                <Button onClick={() => select(-1, 0)}>Left</Button>
                <Button onClick={() => select(1, 0)}>Right</Button>
            </Box>
            <SelectedTile />

            
        </Box>
    )
})

export default AccessibleMap
